import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/components/LayoutPost.js";
import Video from "../components/Video";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div {...{
      "className": "fixed-width"
    }}><h1 parentName="div">{`Meow Wolf Las Vegas`}</h1><hr parentName="div"></hr><div parentName="div" {...{
        "className": "auto-grid"
      }}><div parentName="div"><p parentName="div">{`The Las Vegas project was hot on the heels of the Denver site and used a lot of the same techniques. To see more, checkout `}<Link to="/denver" mdxType="Link">{`Meow Wolf Denver`}</Link>{`.`}</p></div><div parentName="div"><blockquote parentName="div">
            <p parentName="blockquote">{`Codebase: PHP (Wordpress), JS, LESS`}</p>
          </blockquote><Video type="youtube" url="WYAySgXmXF0" mdxType="Video" /></div></div></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      